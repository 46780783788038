.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  background-color: #000000;
}
.headerLogoText {
  color: #97f7dd;
}
.header-left {
  margin-right: 2px;
  border-radius: 4px;
}
