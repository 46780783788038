.scroll-to-top-button {
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 50px; /* Set a fixed width */
  height: 50px; /* Set a fixed height */
  display: flex; /* Center the icon horizontally */
  align-items: center; /* Center the icon vertically */
  justify-content: center; /* Center the icon horizontally */
  font-size: 18px;
  color: black;
  border: none;
  border-radius: 50%; /* Use 50% to make it circular */
  cursor: pointer;
  z-index: 1000;
}
