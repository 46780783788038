.adminSettingCard {
  border: 1px transparent solid;
  background-color: #f7f7f7;
  border-radius: 8px;
}

.mainCard {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 16px;
}
