.sectionCenter {
  margin: auto;
  max-width: 1200px;
  padding: 16px;
}

/* Default styles */
.header,
.top-section {
  background-color: transparent; /* Default background */
}

.featured-section {
  background-color: black; /* Default background */
}

/* Styles when secondary color is present */
.header.secondary-color,
.top-section.secondary-color {
  background-color: var(--secondary-color); /* Use the secondary color */
}

.featured-section.secondary-color {
  background-color: white; /* White background for featured section */
}
