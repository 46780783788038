* {
  box-sizing: border-box;

  text-decoration: none;
}

html,
body,
#root,
main {
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  position: relative;
}

main {
  outline: none;
}

.main--noPadding {
  padding-top: 0;
}

.section--dark {
  background: var(--blue);
  color: var(--white);
}

.section--fullscreen {
  min-height: 100vh;
  min-height: calc(100vh - 56px);
}

section.section--noVPadding {
  padding-top: 0;
  padding-bottom: 0;
}

.sectionCenter {
  margin: auto;
  max-width: 1200px;
  padding: 16px;
}

.sectionCentre--thin {
  max-width: 632px;
}

.sectionCentre--flex {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.section--breakout {
  width: 100vw;
  margin-left: -50vw;
  position: relative;
  left: 50%;
}

@font-face {
  font-family: "LabGrotesque";
  src: url("./fonts/LabGrotesque-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "LabGrotesque";
  src: url("./fonts/LabGrotesque-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "LabGrotesque";
  src: url("./fonts/LabGrotesque-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
